import React from "react";
import portfolioData from "./portfolioData.json";
import classNames from "classnames";

const Portfolio = (props) => {
	const {
		setSelectedProject,
		setModalIsOpen,
		setImageIsOpen,
		setSelectedImagePath,
		setImageAltText,
	} = props;

	const openModal = (portfolioID) => {
		setModalIsOpen(true);
		setSelectedProject(portfolioID);
	};

	const openImage = (imagePath, imageAltText) => {
		setImageIsOpen(true);
		setSelectedImagePath(imagePath);
		setImageAltText(imageAltText);
	};

	const handleImageKeyDown = (e, imagePath, imageAltText) => {
		if (e.key === "Enter" || e.key === " ") {
			openImage(imagePath, imageAltText);
		}
	};

	const PortfolioList = () =>
		portfolioData.map((item, i) => {
			const imagePath = "./images/projects/" + item.image;
			const imageAltText = item.altImageText;
			const isFeatured = item.featured;

			return (
				<li className={classNames({ featured: isFeatured })} key={i}>
					<div
						onClick={() => openImage(imagePath, imageAltText)}
						role="button"
						onKeyDown={(e) => handleImageKeyDown(e, imagePath, imageAltText)}
						tabIndex={0}
						className="image-wrapper"
					>
						<img
							src={imagePath}
							alt={"Featured image for " + item.title}
							className={classNames({ borderless: isFeatured })}
						/>
					</div>
					<div>
						<header>
							<h3>{item.title}</h3>
							<p className="year">{item.year}</p>
							<ul className="tags">
								{item.tags.map((tag, i) => {
									return <li key={i}>{tag}</li>;
								})}
							</ul>
						</header>
						<p>{item.description}</p>
						{item.url && (
							<a href={item.url} target="_blank" rel="noreferrer">
								Visit {item.title}
							</a>
						)}
						<br />
						{item.portfolioId && (
							<button
								onClick={() => openModal(item.portfolioId)}
								onKeyDown={() => openModal(item.portfolioId)}
							>
								Details
							</button>
						)}
					</div>
				</li>
			);
		});

	return (
		<section className="portfolio" id="portfolio">
			<div name="portfolio">
				<h2>My Work</h2>
			</div>
			<ul>
				<PortfolioList />
			</ul>
		</section>
	);
};

export default Portfolio;
